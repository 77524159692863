export const PAYMENT_METHODS = [
  'PAY_IN_14_DAYS',
  'PAY_IN_30_DAYS',
  'PAY_IN_3_PARTS',
  'PAY_IN_6_PARTS',
  'PAY_IN_9_PARTS',
  'PAY_IN_12_PARTS',
  'ETPAY',

  // todo if adding a method in future, it should be added here
];
