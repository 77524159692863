import { format, utcToZonedTime } from 'date-fns-tz';
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'

import { WITH_TIME } from '../../../constants/dateFormats';

/**
 * @param props
 * @param props.date
 * @param props.formatType
 */
export default function DateFormat({ date, formatType = WITH_TIME, culture = 'es' }) {
  if (!date) {
    return ' ';
  }

  try {
    const chile_timezone = 'America/Santiago';

    const chile_date = utcToZonedTime(date, chile_timezone)

    const result = format(chile_date, formatType, {
      timeZone: chile_timezone,
      locale: culture === 'es' ? es : enUS,
    });

    return result;
  } catch (e) {
    console.error('err format time', e);
    return '';
  }
}
