import Box from '@material-ui/core/esm/Box';
import Button from '@material-ui/core/esm/Button';
import CircularProgress from '@material-ui/core/esm/CircularProgress';
import CloseTwoToneIcon from '@material-ui/icons/esm/CloseTwoTone';
import Drawer from '@material-ui/core/esm/Drawer';
import Fab from '@material-ui/core/esm/Fab';
import Grid from '@material-ui/core/esm/Grid';
import TextField from '@material-ui/core/esm/TextField';
import Tooltip from '@material-ui/core/esm/Tooltip';
import MuiAlert from '@material-ui/lab/esm/Alert';

import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { useAuthContext } from '../../providers/AuthProvider';

import {
  resetMerchantAliasFormData,
  setMerchantAliasFormData,
} from '../../reducers/ThemeOptions';
import { createMerchantAlias, updateMerchantAlias } from '../../providers/api';

/**
 * @param props
 * @param props.currentMerchantAliasFormData
 * @param props.setMerchantAliasFormData
 * @param props.resetMerchantAliasFormData
 * @param props.filterMerchant
 */
function MerchantAliasEditSettings({
  currentMerchantAliasFormData,
  setMerchantAliasFormData,
  resetMerchantAliasFormData,
  filterMerchant,
}) {
  const {
    open,
    memberId,
    aliasNumber,
    isAlertVisible,
    isLoading,
    isCreateSessionOnDashboardEnabledMerchant,
    ...formData
  } = currentMerchantAliasFormData;
  const [errorMessage, setErrorMessage] = useState(null);
  const { isReadonly } = useAuthContext();

  const onFormSubmit = async (values, actions) => {
    setErrorMessage(null);
    const {
      ...data
    } = values;

    setMerchantAliasFormData({
      isAlertVisible: false,
      isLoading: true,
    });

    try {
      const { data: responseData } = memberId
        ? await updateMerchantAlias(memberId, data)
        : await createMerchantAlias(filterMerchant, data);

      setMerchantAliasFormData({
        open: false,
        isAlertVisible: true,
        isLoading: false,
        aliasNumber: aliasNumber + 1,
      });

      setTimeout(() => {
        setMerchantAliasFormData({
          isAlertVisible: false,
        });
      }, 15000);
    } catch (error) {
      console.error('create/update alias error', error);

      if (error?.response?.data?.message) {
        const { message } = error.response.data;
        if (Array.isArray(message)) {
          setErrorMessage(message.join(', '));
        } else {
          setErrorMessage(message);
        }
      }

      setMerchantAliasFormData({
        isLoading: false,
      });
    }
  };

  return (
    <>
      {isAlertVisible && (
        <MuiAlert className="alert-wrap" severity="info">
          {memberId ? (
            <FormattedMessage id="Edit successful" />
          ) : (
            <FormattedMessage id="Create successful" />
          )}
        </MuiAlert>
      )}
      <Drawer
        anchor="right"
        elevation={4}
        open={open}
        variant="persistent"
        onClose={resetMerchantAliasFormData}
      >
        <Box
          className="app-header-drawer"
          flexGrow={1}
          style={{ height: '100%' }}
        >
          <Tooltip
            placement="left"
            title={<FormattedMessage id="Close form" />}
          >
            <Fab
              className="drawer-close-btn"
              color="secondary"
              onClick={resetMerchantAliasFormData}
            >
              <CloseTwoToneIcon />
            </Fab>
          </Tooltip>
          <PerfectScrollbar>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              minHeight="100%"
              p={4}
            >
              <div className="font-weight-bold font-size-xxl mb-4 text-black">
                {memberId ? (
                  <FormattedMessage id="Edit merchant alias" />
                ) : (
                  <FormattedMessage id="Create merchant alias" />
                )}
              </div>
              <motion.div
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                initial={{ opacity: 0, y: 20 }}
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Formik
                  enableReinitialize
                  initialValues={formData}
                  onSubmit={onFormSubmit}
                >
                  {() => (
                    <Form noValidate="">
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <Field name="name">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                disabled={isReadonly}
                                label={<FormattedMessage id="Name" />}
                                required={!memberId || false}
                                variant="outlined"
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                          <Field name="login">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                disabled={isReadonly || memberId}
                                label={<FormattedMessage id="Login" />}
                                required={!memberId || false}
                                variant="outlined"
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                          <Field name="password">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                disabled={isReadonly}
                                inputProps={{ autoComplete: 'new-password' }}
                                label={<FormattedMessage id="Password" />}
                                required={!memberId || false}
                                type="password"
                                variant="outlined"
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>

                        <Grid item sm={6} />

                        <Grid item sm={6}>
                          <Field
                            component={CheckboxWithLabel}
                            disabled={isReadonly}
                            Label={{
                              label: (
                                <FormattedMessage id="Read only" />
                              ),
                              className: 'mb-0-spacing',
                            }}
                            name="isReadonly"
                            type="checkbox"
                          />
                          <br />
                        </Grid>

                        <Grid item sm={6}>
                          <Field
                            component={CheckboxWithLabel}
                            disabled={isReadonly}
                            Label={{
                              label: (
                                <FormattedMessage id="Active" />
                              ),
                              className: 'mb-0-spacing',
                            }}
                            name="isActive"
                            type="checkbox"
                          />
                          <br />
                        </Grid>
                      </Grid>

                      {isCreateSessionOnDashboardEnabledMerchant && (
                        <Grid item sm={12}>
                          <Field
                            component={CheckboxWithLabel}
                            disabled={isReadonly}
                            Label={{
                              label: (
                                <FormattedMessage id="Merchant alias can create sessions in store" />
                              ),
                              className: 'mb-0-spacing',
                            }}
                            name="isCreateSessionOnDashboardEnabled"
                            type="checkbox"
                          />
                          <br />
                        </Grid>
                      )}

                      {errorMessage && (
                        <Grid container justify="center" spacing={4}>
                          <Grid item>
                            <div className="text-danger">{errorMessage}</div>
                          </Grid>
                        </Grid>
                      )}
                      <br />

                      <Grid container justify="space-between" spacing={4}>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={resetMerchantAliasFormData}
                          >
                            <FormattedMessage id="Cancel" />
                          </Button>
                        </Grid>
                        <Grid item>
                          {isLoading && (
                            <CircularProgress
                              className="circular-progress"
                              color="secondary"
                              size={18}
                            />
                          )}
                          <Button
                            disableElevation
                            color="primary"
                            disabled={isLoading || isReadonly}
                            type="submit"
                            variant="contained"
                          >
                            <FormattedMessage id="Save" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </motion.div>
            </Box>
          </PerfectScrollbar>
        </Box>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentMerchantAliasFormData: state.ThemeOptions.currentMerchantAliasFormData,
  filterMerchant: state.Filters.merchantAliasesFiltersMerchant,
});

const mapDispatchToProps = (dispatch) => ({
  setMerchantAliasFormData: (payload) => dispatch(setMerchantAliasFormData(payload)),
  resetMerchantAliasFormData: () => dispatch(resetMerchantAliasFormData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantAliasEditSettings);
