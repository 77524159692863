import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext(null);

export const roles = {
  ADMIN: 'ADMIN',
  MERCHANT: 'MERCHANT',
  CUSTOMER: 'CUSTOMER',
  EXPRESS_PAYMENT: 'EXPRESS_PAYMENT',
};

const initialAuthData = {
  token: null,
  name: null,
  role: null,
  isCreateSessionOnDashboardEnabled: 'no',
  isReadonly: 'no',
  permissions: '',
  isMerchantAlias: 'no',
};

const AuthProvider = (props) => {
  const history = useHistory();

  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'name',
    'role',
    'isReadonly',
    'isCreateSessionOnDashboardEnabled',
    'permissions',
    'isMerchantAlias',
  ]);
  const [authData, setAuthData] = useState(initialAuthData);

  const onLogout = useCallback(() => {
    removeCookie('token', { path: '/' });
    removeCookie('name', { path: '/' });
    removeCookie('role', { path: '/' });
    removeCookie('isCreateSessionOnDashboardEnabled', { path: '/' });
    removeCookie('isReadonly', { path: '/' });
    removeCookie('permissions', { path: '/' });
    removeCookie('isMerchantAlias', { path: '/' });

    setAuthData(initialAuthData);

    history.push('/');
  }, [history, removeCookie]);

  const onLogin = useCallback(
    (newAuthData) => {
      const {
        token,
        name,
        role,
        expiresAt,
        isCreateSessionOnDashboardEnabled,
        isReadonly,
        permissions,
        isMerchantAlias,
      } = newAuthData;

      if (role === roles.CUSTOMER) {
        // customer cookies are save only for this session, should login again if browser is closed
        setCookie('token', token, { path: '/' });
        setCookie('name', name, { path: '/' });
        setCookie('role', role, { path: '/' });
        setCookie(
          'isCreateSessionOnDashboardEnabled',
          isCreateSessionOnDashboardEnabled ? 'yes' : 'no',
          { path: '/' },
        );
      } else if (role === roles.EXPRESS_PAYMENT) {
        // customer cookies are save only for this session, should login again if browser is closed
        setCookie('token', token, { path: '/' });
        setCookie('name', name, { path: '/' });
        setCookie('role', role, { path: '/' });
      } else if (role === roles.ADMIN) {
        const expiresAtDate = new Date(expiresAt);
        setCookie('token', token, { path: '/', expires: expiresAtDate });
        setCookie('name', name, { path: '/', expires: expiresAtDate });
        setCookie('role', role, { path: '/', expires: expiresAtDate });
        setCookie('isReadonly', isReadonly ? 'yes' : 'no', {
          path: '/',
          expires: expiresAtDate,
        });
        setCookie(
          'isCreateSessionOnDashboardEnabled',
          isCreateSessionOnDashboardEnabled ? 'yes' : 'no',
          {
            path: '/',
            expires: expiresAtDate,
          },
        );
        setCookie('permissions', permissions, { path: '/', expires: expiresAtDate });
      } else {
        const expiresAtDate = new Date(expiresAt);
        setCookie('token', token, { path: '/', expires: expiresAtDate });
        setCookie('name', name, { path: '/', expires: expiresAtDate });
        setCookie('role', role, { path: '/', expires: expiresAtDate });
        setCookie(
          'isCreateSessionOnDashboardEnabled',
          isCreateSessionOnDashboardEnabled ? 'yes' : 'no',
          {
            path: '/',
            expires: expiresAtDate,
          },
        );
        setCookie('isReadonly', isReadonly ? 'yes' : 'no', {
          path: '/',
          expires: expiresAtDate,
        });
        setCookie('isMerchantAlias', isMerchantAlias ? 'yes' : 'no', {
          path: '/',
          expires: expiresAtDate,
        });
      }

      setAuthData(newAuthData);
    },
    [setCookie],
  );

  useEffect(() => {
    if (cookies.token && cookies.name && cookies.role) {
      setAuthData({
        token: cookies.token,
        name: cookies.name,
        role: cookies.role,
        isReadonly: cookies.isReadonly === 'yes',
        isCreateSessionOnDashboardEnabled:
        cookies.isCreateSessionOnDashboardEnabled === 'yes',
        permissions: cookies.permissions,
        isMerchantAlias: cookies.isMerchantAlias === 'yes',
      });
    }
  }, [cookies]);

  const authDataValue = useMemo(
    () => ({
      ...authData,
      onLogin,
      setCookie,
      onLogout,
    }),
    [authData, onLogin, setCookie, onLogout],
  );

  return <AuthContext.Provider value={authDataValue} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
