// must be synchronized with permission_matrix.id
// also look at PermissionMatrixID enum on Backend
export const PERMISSION_MATRIX_IDS = {
  VIEW_USER_MANAGEMENT: 'VIEW_USER_MANAGEMENT',
  CREATE_USER: 'CREATE_USER',
  EDIT_USER: 'EDIT_USER',
  CREATE_ROLE: 'CREATE_ROLE',
  EDIT_ROLE: 'EDIT_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_CUSTOMERS: 'VIEW_CUSTOMERS',
  VIEW_CUSTOMER_INFO: 'VIEW_CUSTOMER_INFO',
  BLACKLIST_MODIFICATION: 'BLACKLIST_MODIFICATION',
  CREATE_MANUAL_PAYMENT: 'CREATE_MANUAL_PAYMENT',
  EDIT_CUSTOMER_INFO: 'EDIT_CUSTOMER_INFO',
  EDIT_BANK_INFO: 'EDIT_BANK_INFO',
  RENEW_BANK_ID: 'RENEW_BANK_ID',
  VIEW_KYC_INFO: 'VIEW_KYC_INFO',
  CANCEL_ALL_INVOICES: 'CANCEL_ALL_INVOICES',
  VIEW_INVOICES: 'VIEW_INVOICES',
  EXPORT_INVOICES_TO_CSV: 'EXPORT_INVOICES_TO_CSV',
  CREATE_SETTLEMENT: 'CREATE_SETTLEMENT',
  VIEW_INVOICE_INFO: 'VIEW_INVOICE_INFO',
  GET_INVOICE_PDF: 'GET_INVOICE_PDF',
  SET_INVOICE_PAID: 'SET_INVOICE_PAID',
  COMPENSATE_AMOUNT: 'COMPENSATE_AMOUNT',
  ADD_HOLD_PERIOD: 'ADD_HOLD_PERIOD',
  VIEW_ORDERS: 'VIEW_ORDERS',
  VIEW_ORDER_INFO: 'VIEW_ORDER_INFO',
  CANCEL_ORDER: 'CANCEL_ORDER',
  PARTIAL_CANCEL_ORDER: 'PARTIAL_CANCEL_ORDER',
  CHANGE_PAYMENT_METHOD: 'CHANGE_PAYMENT_METHOD',
  VIEW_SESSIONS: 'VIEW_SESSIONS',
  VIEW_SESSION_INFO: 'VIEW_SESSION_INFO',
  CREATE_SESSION: 'CREATE_SESSION',
  VIEW_PREREGISTRATION: 'VIEW_PREREGISTRATION',
  VIEW_PREREGISTRATION_INFO: 'VIEW_PREREGISTRATION_INFO',
  VIEW_SETTLEMENTS: 'VIEW_SETTLEMENTS',
  VIEW_SETTLEMENT_INFO: 'VIEW_SETTLEMENT_INFO',
  SETTLEMENT_MANUAL_PAYMENT: 'SETTLEMENT_MANUAL_PAYMENT',
  SETTLEMENT_AUTOMATIC_PAYMENT: 'SETTLEMENT_AUTOMATIC_PAYMENT',
  REMOVE_SETTLEMENT: 'REMOVE_SETTLEMENT',
  EXPORT_SETTLEMENTS_TO_CSV: 'EXPORT_SETTLEMENTS_TO_CSV',
  REMOVE_INVOICE_FROM_SETTLEMENT: 'REMOVE_INVOICE_FROM_SETTLEMENT',
  VIEW_SETTLEMENTS_REPORTS: 'VIEW_SETTLEMENTS_REPORTS',
  VIEW_MERCHANTS: 'VIEW_MERCHANTS',
  CREATE_MERCHANT: 'CREATE_MERCHANT',
  EDIT_MERCHANT: 'EDIT_MERCHANT',
  EDIT_FEES: 'EDIT_FEES',
  EDIT_GLOBAL_FEES: 'EDIT_GLOBAL_FEES',
  VIEW_MERCHANT_ALIASES: 'VIEW_MERCHANT_ALIASES',
  CREATE_MERCHANT_ALIAS: 'CREATE_MERCHANT_ALIAS',
  EDIT_MERCHANT_ALIAS: 'EDIT_MERCHANT_ALIAS',
  VIEW_SETTINGS: 'VIEW_SETTINGS',
  SAVE_SETTINGS: 'SAVE_SETTINGS',
  CREATE_DELAY_FEES: 'CREATE_DELAY_FEES',
  VIEW_REFUNDS: 'VIEW_REFUNDS',
  VIEW_REFUND_INFO: 'VIEW_REFUND_INFO',
  REFUND_MANUAL_PAYMENT: 'REFUND_MANUAL_PAYMENT',
  REFUND_AUTOMATIC_PAYMENT: 'REFUND_AUTOMATIC_PAYMENT',
  EXPORT_REFUNDS_TO_CSV: 'EXPORT_REFUNDS_TO_CSV',
  VIEW_IPS_BLACKLIST: 'VIEW_IPS_BLACKLIST',
  ADD_IP_TO_BLACKLIST: 'ADD_IP_TO_BLACKLIST',
  DELETE_IP_FROM_BLACKLIST: 'DELETE_IP_FROM_BLACKLIST',
  VIEW_ROUTINES: 'VIEW_ROUTINES',
  CREATE_ROUTINE: 'CREATE_ROUTINE',
  DEACTIVATE_ROUTINE: 'DEACTIVATE_ROUTINE',
  VIEW_EMAIL_TEMPLATES: 'VIEW_EMAIL_TEMPLATES',
  CREATE_EMAIL_TEMPLATE: 'CREATE_EMAIL_TEMPLATE',
  EDIT_EMAIL_TEMPLATE: 'EDIT_EMAIL_TEMPLATE',
  SEND_EMAIL_TEMPLATE: 'SEND_EMAIL_TEMPLATE',
};

export default PERMISSION_MATRIX_IDS;
