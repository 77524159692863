import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';
import { validate as validateRut } from 'rut.js';

import { BanksPossibleKeys } from '../constants/banks';
import { AccountTypePossibleKeys } from '../constants/bankAccountTypes';
import { MIN_IN_STORE_AMOUNT } from '../constants/inStoreLimit';

/**
 * @description
 * This file contains validation schemas that can be used in different cases: logn, sign in, change password pages, etc.
 */

// Min 10 characters, at least 1 uppercase, 1 lowercase letters, 1 number and 1 special character
const passwordRegex = /^(?!.*\s)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_=+\[\]{}|\\;:'",.<>\/?`~\-]).{10,}$/;

export const userNameSchema = yup.string().required('This field is required.');

export const userPasswordSchema = yup
  .string()
  .required('This field is required.');
// .min(6, "Password is too short.")

export const firstName = yup
  .string()
  .required('Name is required.')
  .min(1, 'Should be more than 1.')
  .max(30, 'Should be less than 30.');

export const lastName = yup.string().required('Last name is required.');

export const fullName = yup
  .string()
  .required('Name is required.')
  .min(1, 'Should be more than 1.')
  .max(60, 'Should be less than 30.');
export const email = yup
  .string()
  .required('Email is required.')
  .email('Must be valid email address.');

export const phone = yup
  .string()
  .required('Phone is required.')
  .test(
    'Phone validation',
    'Phone must be at least 8 characters.',
    async (phoneVal = '') => {
      try {
        const phoneCleaned = phoneVal.replace(/\D/g, '');
        const phoneNumber = parsePhoneNumber(`+569${phoneCleaned}`, 'CL');

        if (!phoneNumber) {
          return false;
        }

        return phoneNumber.isValid();
      } catch {
        return false;
      }
    },
  );

export const rut = yup.string().required('Rut is required.');

export const country = yup.string().required('Country is required.');

export const region = yup.string().required('Region is required.');

export const city = yup.string().required('City is required.');

export const street = yup.string().required('Street is required.');

export const amount = yup
  .number('You must enter a valid amount')
  .required('You must enter a valid amount')
  .integer('Amount must be an integer')
  .min(MIN_IN_STORE_AMOUNT, `Amount must be above ${MIN_IN_STORE_AMOUNT}`);

export const customerLoginSchema = yup.object().shape({
  phone,
});

export const newSessionSchema = yup.object().shape({
  phone,
  amount,
});

export const loginSchema = yup.object().shape({
  name: yup.string().required('Name field is required.'),
  password: yup.string().required('Password field is required.'),
});

export const updateUserSchema = yup.object().shape({
  fullName,
  email,
  rut,
  country,
  region,
  city,
  street,
});

export const feeSettingSchema = yup.object().shape({
  activatedAt: yup.date(),
  expiredAt: yup.date().nullable(true),
  consumerFee: yup.number().min(0).max(100),
  merchantFee: yup
    .number()
    .min(0)
    .max(100)
    .nullable(true)
    .transform((v) => (v === '' ? null : v)),
  merchantFlatFee: yup.number().min(0),
  paymentMethod: yup.string(),
});

export const bank = yup.string().required('Bank is required').min(1, 'Bank is required').oneOf(BanksPossibleKeys, 'Bank is required');
export const password = yup.string().required('Password is required.');

export const rutWithValidation = yup
  .string()
  .required('RUT is required.')
  .test(
    'RUT validation',
    'RUT is invalid',
    async (rutVal = '') => {
      return validateRut(rutVal);
    },
  );

export const bankIdSchema = yup.object().shape({
  bank: bank,
  rut: rutWithValidation,
  password: password,
});

export const accountTypeValidation = yup
  .string()
  .required('Account type is required')
  .min(1, 'Account type is required')
  .oneOf(AccountTypePossibleKeys, 'Account type is required');

export const accountNumberValidation = yup
  .number()
  .required('Account number is required')
  .min(1, 'Account number is required');

export const updateCustomerBankInfoSchema = yup.object().shape({
  bank: bank,
  accountType: accountTypeValidation,
  accountNumber: accountNumberValidation,
});

export const expressPaymentLoginSchema = yup.object().shape({
  rut: rutWithValidation,
});

const newUserPassword = password.min(10, 'Password must be minimum 10 characters.').matches(passwordRegex, { message: 'Invalid password format.' });

export const createUserSchema = yup.object().shape({
  name: firstName,
  email,
  password: newUserPassword,
});

export const editUserSchema = yup.object().shape({
  name: firstName,
});

export const editUserSchemaWithPassword = yup.object().shape({
  name: firstName,
  password: newUserPassword,
});
