import BancoBCIImg from '../assets/images/banks/banco_bci2x.png';
import BancoBICEImg from '../assets/images/banks/banco_bice2x.png';
import BancoDeChileImg from '../assets/images/banks/banco_de_chile2x.png';
import BancoEstadoImg from '../assets/images/banks/banco_estado2x.png';
import BancoItauImg from '../assets/images/banks/banco_itau2x.png';
import BancoSecurityImg from '../assets/images/banks/banco_security2x.png';
import FalabellaImg from '../assets/images/banks/falabella2x.png';
import ScotiaBankImg from '../assets/images/banks/scotiabank2x.png';
import SantanderBankImg from '../assets/images/banks/banco_santander2x.png';
import RipleyBankImg from '../assets/images/banks/banco_ripley.png';

export const Banks = [
  {
    name: 'Santander',
    value: 'santander',
    key: 37,
    img: SantanderBankImg,
  },
  {
    name: 'Bci',
    value: 'bci',
    key: 16,
    img: BancoBCIImg,
  },
  {
    name: 'Banco BICE',
    value: 'bice',
    key: 28,
    img: BancoBICEImg,
  },
  {
    name: 'Banco de Chile',
    value: 'chile',
    key: 1,
    img: BancoDeChileImg,
  },
  {
    name: 'Banco Estado',
    value: 'estado',
    key: 12,
    img: BancoEstadoImg,
  },
  {
    name: 'Scotiabank',
    value: 'scotia',
    key: 14,
    img: ScotiaBankImg,
  },
  {
    name: 'Itaú',
    value: 'itau',
    key: 39,
    img: BancoItauImg,
  },
  {
    name: 'Banco Security',
    value: 'security',
    key: 49,
    img: BancoSecurityImg,
  },
  {
    name: 'Banco Falabella',
    value: 'falabella',
    key: 51,
    img: FalabellaImg,
  },
  {
    name: 'Banco Ripley',
    value: 'ripley',
    key: 53,
    img: RipleyBankImg,
  },
];

const dict = {};

// eslint-disable-next-line no-restricted-syntax
for (const b of Banks) {
  dict[b.value] = b.name;
}

export const BanksDict = dict;

export const BanksPossibleKeys = Banks.map(b => b.value);
