/**
 * @param props
 * @param props.value
 */
export function amountFormat(value) {
  if (value == null) {
    return '';
  }

  const isNegative = value < 0;

  let valMutable = Math.round(Math.abs(value)).toString();

  let valFormated = '';

  while (valMutable.length > 3) {
    valFormated = `.${valMutable.slice(-3)}${valFormated}`;
    valMutable = valMutable.slice(0, Math.max(0, valMutable.length - 3));
  }

  valFormated = valMutable + valFormated;

  return `$${isNegative ? '-' : ''}${valFormated}`;
}

export default function AmountFormat({ value }) {
  return amountFormat(value);
}
