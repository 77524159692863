export const SUCCESS = 'Success';
export const ERROR = 'Error';
export const FAILED = 'Failed';

export const SESSION_STATUSES = [
  'CONFIRMED',
  'DENIED',
  'ERROR',
  'ABORTED',
  'FAILED',
  'EXPIRED',
  'PAYMENT_METHOD',
  'PIN',
  'PIN_WRONG',
  'BANK_ONBOARDING',
  'BANK_ONBOARDING_PENDING',
  'CLAVE_UNICA_DEBTS',
  'CLAVE_UNICA_DEBTS_PENDING',
  'CUSTOMER_DETAILS',

  // Samsung statuses
  'PAYMENT_METHOD_AND_AMOUNT',
  'PHONE',
  'EMAIL',
];
