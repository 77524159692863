export const SettlementIntervalTypes = [
    {
        name: 'Daily',
        value: 'DAILY'
    },
    {
        name: 'Weekly',
        value: 'WEEKLY'
    },
    {
        name: 'Monthly',
        value: 'MONTHLY'
    }
];
