import Box from '@material-ui/core/esm/Box';
import Checkbox from '@material-ui/core/esm/Checkbox';
import blueGrey from '@material-ui/core/esm/colors/blueGrey';
import grey from '@material-ui/core/esm/colors/grey';
import lime from '@material-ui/core/esm/colors/lime';
import Paper from '@material-ui/core/esm/Paper';
import Table from '@material-ui/core/esm/Table';
import TableBody from '@material-ui/core/esm/TableBody';
import TableCell from '@material-ui/core/esm/TableCell';
import TableContainer from '@material-ui/core/esm/TableContainer';
import TableHead from '@material-ui/core/esm/TableHead';
import TableRow from '@material-ui/core/esm/TableRow';
import Icon from '@material-ui/core/esm/Icon';
import Typography from '@material-ui/core/esm/Typography';
import makeStyles from '@material-ui/styles/esm/makeStyles';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  head: (props) => ({
    backgroundColor: props.isCustomerTable ? grey[0] : grey[200],
  }),
  root: {
    borderBottom: 'none',
  },
  td: {
    whiteSpace: 'nowrap',
    '& a': { textDecoration: 'underline dotted' },
  },
  tr: {
    transition: 'background 0.2s ease',
    '&:nth-of-type(odd)': {
      backgroundColor: grey[50],
    },
    '&:nth-of-type(even)': {
      backgroundColor: blueGrey[50],
    },
    '&:hover': {
      backgroundColor: lime[50],
    },
  },
});

const isAllSelectedCheck = (newSelected) =>
  newSelected
    .filter((row) => !row.checkboxDisabled)
    .every((row) => row.checked);

/**
 *
 * @param props
 * @param props.data
 * @param props.columns
 * @param props.onRowClick
 * @param props.withCheckboxes
 * @param props.onSelect
 * @param props.isCustomerTable
 */
export default function MainTable({
  data = [],
  columns,
  onRowClick,
  withCheckboxes,
  onSelect,
  isCustomerTable,
  onOrderChange,
  orderBy,
  orderDirection,
  styles,
}) {
  const currentStyles = useStyles({ isCustomerTable });

  const classes = styles || currentStyles;
  const [internalData, setInternalData] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [lastSortableColumn, setLastSortableColumn] = useState(null);

  useEffect(() => {
    const internalDataRaw = data.map((row) => ({
      ...row,
      checked: row.checked || false,
      disabled: row.disabled || false,
    }));

    setIsAllSelected(isAllSelectedCheck(internalDataRaw));
    setInternalData(internalDataRaw);
  }, [data, withCheckboxes]);

  const handleSelectAll = (event) => {
    const newSelected = internalData.map((row) => ({
      ...row,
      checked: row.checkboxDisabled ? row.checked : event.target.checked,
    }));

    setIsAllSelected(isAllSelectedCheck(newSelected));
    setInternalData(newSelected);

    if (onSelect) {
      onSelect(newSelected.filter((row) => row.checked));
    }
  };

  const handleSelectRow = (index, event) => {
    const newSelected = internalData.map((row, arrIndex) =>
      arrIndex === index ? { ...row, checked: event.target.checked } : row,
    );

    setIsAllSelected(isAllSelectedCheck(newSelected));
    setInternalData(newSelected);

    if (onSelect) {
      onSelect(newSelected.filter((row) => row.checked));
    }
  };

  const handleChangeOrder = (column) => {
    if (onOrderChange) {
      const newOrderDirection = (lastSortableColumn !== column.field || !orderDirection) ? 'ASC' : (orderDirection === 'ASC' ? 'DESC' : null);
      const newField = newOrderDirection ? column.field : null;

      onOrderChange(newField, newOrderDirection);
      setLastSortableColumn(newField);
    }
  };

  return (
    <>
      <TableContainer component={isCustomerTable ? Box : Paper}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead className={classes.head}>
            <TableRow>
              {!withCheckboxes ? null : (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                  />
                </TableCell>
              )}
              {columns.map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={`table-header-column-${index}`} className={classes.td}>
                  {column.sortable ? (
                    <>
                      <Icon
                        style={{ cursor: 'pointer', fontSize: 14 }}
                        onClick={() => handleChangeOrder(column)}
                      >
                        {!orderDirection || orderBy !== column.field
                          ? 'unfold_more'
                          : orderDirection === 'ASC'
                            ? 'arrow_drop_up'
                            : 'arrow_drop_down'}
                      </Icon>{' '}
                      &nbsp;
                    </>
                  ) : null}
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                // eslint-disable-next-line react/no-array-index-key
                key={`table-row-${index}`}
                className={classes.tr}
                style={onRowClick ? { cursor: 'pointer' } : null}
                onClick={onRowClick ? (e) => onRowClick(e, row) : null}
              >
                {!withCheckboxes ? null : (
                  <TableCell
                    padding="checkbox"
                    key={`table-checkbox-${index}`}
                  >
                    <Checkbox
                      checked={
                        (internalData[index] &&
                          !!internalData[index].checked) ||
                        false
                      }
                      disabled={row.checkboxDisabled || false}
                      name={`checkbox-${index}`}
                      onClick={(event) => handleSelectRow(index, event)}
                    />
                  </TableCell>
                )}
                {columns.map((column, indexColumn) => (
                  <TableCell
                    // eslint-disable-next-line react/no-array-index-key
                    key={`table-column-${indexColumn}`}
                    align={column.align ? column.align : 'inherit'}
                    className={classes.td}
                    style={column.cellStyle ? column.cellStyle : null}
                  >
                    {column.render ? column.render(row, index) : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {data.length === 0 && (
        <Typography align="center" variant="body1">
          <FormattedMessage id="No available data" />
        </Typography>
      )}
    </>
  );
}
