import axios from 'axios';
import { Cookies } from 'react-cookie';

import variables from '../config/variables';

import { SESSION_STATUSES } from '../constants/SessionStatuses';
import { SESSION_TYPES } from '../constants/SessionTypes';

export const baseUrl = variables.apiUrl;
const cookie = new Cookies();
axios.interceptors.request.use(
  (config) => {
    const token = cookie.get('token');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 401 || error.response.status === 406) &&
      error.response.config &&
      error.response.config.url !== '/api/users/login'
    ) {
      cookie.remove('token');
      cookie.remove('name');
      cookie.remove('role');
      document.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export const login = async (login, password) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login`,
    data: {
      login,
      password,
    },
  });

export const createCustomerPin = async (phone) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/create-pin`,
    data: {
      phone,
    },
  });

export const resendCustomerPin = async (phone) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/resend-pin`,
    data: {
      phone,
    },
  });

export const customerChangePhoneNumber = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/resend-pin`,
    data,
  });

export const checkCustomerPinResendIsAvailable = async (phone) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/check-resend-pin-is-available`,
    data: {
      phone,
    },
  });

export const getCustomerPinExpirationTime = async (phone) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/get-pin-expiration-time`,
    data: {
      phone,
    },
  });

export const changeCustomerAvatar = async (image) => {
  const formData = new FormData();
  formData.append('file', image);

  return axios({
    method: 'post',
    url: `${baseUrl}/api/customers/profile-avatar`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getCustomerInfo = async (customerId) => {
  const currentCustomerId = customerId ? `?customer_id=${customerId}` : '';
  return axios({
    method: 'get',
    url: `${baseUrl}/api/customers/simple-profile${currentCustomerId}`,
  });
};

export const changeCustomerInfo = async (customerId, body) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/customers/${customerId}`,
    data: body,
  });

export const fetchCustomers = async (
  currentPage = 0,
  searchQuery,
  filterDebtStatus,
  orderBy,
  orderDirection
) => axios({
  method: 'get',
  url: `${baseUrl}/api/customers/?page=${currentPage}${searchQuery && searchQuery.length >= 3
    ? `&input=${encodeURIComponent(searchQuery)}`
    : ''
    }${filterDebtStatus !== 'ALL' ? `&debt_status_filter=${filterDebtStatus}` : ''
    }${orderBy ? `&order_by=${orderBy}` : ''
    }${orderDirection ? `&order_direction=${orderDirection}` : ''
    }`,
});

export const fetchInvoices = async (
  currentPage = 0,
  searchQuery,
  amountSearchQuery,
  minDate,
  maxDate,
  selectedMerchantId,
  settlementFilter,
  cancelledFilter,
  filterPaymentStatus,
  filterOverdue,
  orderBy,
  orderDirection,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }${amountSearchQuery
        ? `&amount_input=${encodeURIComponent(amountSearchQuery)}`
        : ''
      }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${
        maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
      }${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''}${settlementFilter === 'SETTLED'
        ? `&is_settled=true`
        : settlementFilter === 'UNSETTLED'
          ? `&is_settled=false`
          : ''
      }${cancelledFilter && cancelledFilter.length > 0
          ? `&cancelled_filter=${cancelledFilter.join(',')}`
          : ''
      }${filterPaymentStatus && filterPaymentStatus.length > 0
        ? `&invoice_statuses=${filterPaymentStatus.join(',')}`
        : ''
      }${orderBy ? `&order_by=${orderBy}` : ''
      }${orderDirection ? `&order_direction=${orderDirection}` : ''
      }${filterOverdue === 'OVERDUE'
        ? '&is_overdue=true'
        : (filterOverdue === 'NOT_OVERDUE'
          ? '&is_overdue=false'
          : (filterOverdue === 'NONE' ? '&is_overdue=null' : ''))
      }`,
  });

export const fetchInvoice = async (invoiceNumber) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/${invoiceNumber}`,
  });

export const fetchMerchants = async (currentPage = 0, searchQuery) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/merchants/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }`,
  });

export const fetchMerchantsList = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/merchants/list`,
  });

export const fetchMerchantAliasesList = async (merchantId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/merchants/aliases-list${merchantId ? `?merchant_id=${merchantId}` : ''}`,
  });

export const fetchCustomerUserData = async (customerId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/${customerId}`,
  });

export const fetchCustomerUserStats = async (customerId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/${customerId}/stats`,
  });

export const fetchCustomerInvoices = async (customerId, currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/customer/${customerId}?page=${currentPage}`,
  });

export const fetchCustomerSessions = async (customerId, currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/customer/${customerId}?page=${currentPage}`,
  });

export const fetchCustomerOrdersList = async (customerId, currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/orders/customer/${customerId}?page=${currentPage}`,
  });


export const fetchCustomerKycInfo = async (customerId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/${customerId}/kyc`,
  });

export const downloadFile = async (url) => {
  const { data } = await axios({
    method: 'get',
    url,
    responseType: 'blob',
  });
  const objectUrl = URL.createObjectURL(
    new Blob([data], { type: 'application/pdf' }),
  );
  window.open(objectUrl);
};

export const fetchSessions = async (
  currentPage = 0,
  searchQuery,
  showStatuses,
  minDate,
  maxDate,
  selectedMerchantId,
  selectedMerchantAliasId,
  filterType,
  orderBy,
  orderDirection,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
      }${showStatuses &&
        showStatuses.length > 0 &&
        showStatuses.length !== SESSION_STATUSES.length
        ? `&statuses=${showStatuses.join(',')}`
        : ''
      }${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''
      }${selectedMerchantAliasId || selectedMerchantAliasId === 0 ? `&merchant_alias_id=${selectedMerchantAliasId}` : ''
      }${filterType !== 'ALL' ? `&type_filter=${filterType}` : ''
      }${orderBy ? `&order_by=${orderBy}` : ''
      }${orderDirection
        ? `&order_direction=${orderDirection === 'ASC' ? 'ASC' : 'DESC'}`
        : ''
      }`,
  });

export const fetchSession = async (sessionId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/${sessionId}`,
  });

export const fetchSessionEvents = async (sessionId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/${sessionId}/events`,
  });

export const fetchOrders = async (
  currentPage = 0,
  searchQuery,
  // showStatuses,
  minDate,
  maxDate,
  selectedMerchantId,
  selectedMerchantAliasId,
  filterCancelStatus,
  filterPaymentMethod,
  filterPaymentStatus,
  filterOverdue,
  orderBy,
  orderDirection,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/orders/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
      }${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''
      }${selectedMerchantAliasId || selectedMerchantAliasId === 0 ? `&merchant_alias_id=${selectedMerchantAliasId}` : ''
      }${filterCancelStatus && filterCancelStatus.length > 0
        ? `&cancelled_filter=${filterCancelStatus.join(',')}`
        : ''
      }${filterPaymentMethod && filterPaymentMethod.length > 0
        ? `&payment_methods=${filterPaymentMethod.join(',')}`
        : ''
      }${filterPaymentStatus && filterPaymentStatus.length > 0
        ? `&order_statuses=${filterPaymentStatus.join(',')}`
        : ''
      }${filterOverdue === 'OVERDUE'
        ? '&is_overdue=true'
        : (filterOverdue === 'NOT_OVERDUE'
          ? '&is_overdue=false'
          : (filterOverdue === 'NONE' ? '&is_overdue=null' : ''))
      }${orderBy ? `&order_by=${orderBy}` : ''
      }${orderDirection
        ? `&order_direction=${orderDirection === 'ASC' ? 'ASC' : 'DESC'}`
        : ''
      }`,
  });

// ${
//   showStatuses &&
//   showStatuses.length > 0 &&
//   showStatuses.length !== SESSION_STATUSES.length
//     ? `&statuses=${showStatuses.join(',')}`
//     : ''
// }

export const fetchOrder = async (orderId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/orders/${orderId}`,
  });

export const updateCustomer = async (customerId, customerData) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/customers/${customerId}`,
    data: customerData,
  });

export const cancelAllInvoices = async (customerId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/invoices/cancel-all-customer-invoices/${customerId}`,
  });

export const submitMerchant = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/merchants`,
    data,
  });

export const updateMerchant = async (id, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/merchants/update/${id}`,
    data,
  });

export const updateCustomerBlacklistStatus = async (
  customerId,
  newBlacklistStatus,
) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/customers/${customerId}/blacklist`,
    data: { newBlacklistStatus },
  });

export const fetchDashboardData = async (
  dateFrom,
  dateTo,
  selectedMerchantId,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/statistics/dashboard?min_date=${dateFrom}&max_date=${dateTo}${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''
      }`,
  });

export const fetchSettlements = async (
  currentPage = 0,
  searchQuery,
  minDate,
  maxDate,
  selectedMerchantId,
  selectedStatus,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settlements/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
      }${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''
      }${selectedStatus ? `&status=${selectedStatus}` : ''}`,
  });

export const fetchSettlement = async (settlementNumber) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settlements/${settlementNumber}`,
  });

export const createSettlement = async (
  invoices,
  settleDate,
  cancelledInvoices,
  payAmount,
) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/settlements`,
    data: { invoices, settleDate, cancelledInvoices, payAmount },
  });

export const removeSettlement = async (settlementId) =>
  axios({
    method: 'delete',
    url: `${baseUrl}/api/settlements/${settlementId}`,
  });

export const removeInvoiceFromSettlement = async (invoiceNumber) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/invoices/${invoiceNumber}/unsettle`,
  });

export const fetchProfileInfo = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/my-profile`,
  });

export const fetchProfileInvoices = async (currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/customer/my-invoices?page=${currentPage}`,
  });

export const fetchProfileStats = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/my-stats`,
  });

export const fetchLatestCustomerOrders = async (customerId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/orders/customer/latest-orders${
      customerId ? `?customer_id=${customerId}` : ''
    }`,
  });

export const fetchCustomerOrders = async (queryObject) => {
  let queryString = '';
  queryString += queryObject?.current_page
    ? `&page=${queryObject.current_page}`
    : '&page=0';
  if (queryObject?.customer_id) {
    queryString += `&customer_id=${queryObject.customer_id}`;
  }
  if (queryObject?.sort_by) {
    queryString += `&sort_by=${queryObject.sort_by}`;
  }
  if (queryObject?.merchant_id) {
    queryString += `&merchant_id=${queryObject.merchant_id}`;
  }
  if (queryObject?.min_date) {
    queryString += `&min_date=${encodeURIComponent(queryObject.min_date)}`;
  }
  if (queryObject?.max_date) {
    queryString += `&max_date=${encodeURIComponent(queryObject.max_date)}`;
  }
  if (queryObject?.is_include_paid_and_cancelled_orders) {
    queryString += `&is_include_paid_and_cancelled_orders=${queryObject?.is_include_paid_and_cancelled_orders}`;
  }
  return axios({
    method: 'get',
    url: `${baseUrl}/api/orders/customer/orders?${queryString}`,
  });
};

export const fetchCustomerNotification = async (customerId) => {
  const currentCustomerId = customerId ? `?customer_id=${customerId}` : '';
  return axios({
    method: 'get',
    url: `${baseUrl}/api/orders/customer/notification${currentCustomerId}`,
  });
};

export const fetchCustomerMerchants = async (customerId) => {
  const currentCustomerId = customerId ? `?customer_id=${customerId}` : '';
  return axios({
    method: 'get',
    url: `${baseUrl}/api/customers/customer-merchants${currentCustomerId}`,
  });
};

export const fetchCustomerOrderInfo = async (orderId, customerId) => {
  const currentCustomerId = customerId ? `?customer_id=${customerId}` : '';
  return axios({
    method: 'get',
    url: `${baseUrl}/api/orders/customer-order/${orderId}${currentCustomerId}`,
  });
};

export const paySettlement = async (settlementId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/settlements/${settlementId}/pay`,
  });

export const paySettlementManually = async (settlementId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/settlements/${settlementId}/pay-manually`,
  });


export const getNewSettlementData = async (merchantId, settlementAmount) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settlements/latest-settled-cancelled-invoices?merchant_id=${merchantId}&amount=${settlementAmount}`,
  });

export const getSettlementEvents = async (settlementId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settlements/${settlementId}/events`,
  });

export const fetchCustomerSignupDate = async (customerId) => {
  let url = `${baseUrl}/api/orders/customer/signup-date`;

  if (customerId) {
    url += `?customer_id=${customerId}`;
  }

  return axios({
    method: 'get',
    url,
  });
};

export const checkAssetsForPhoneChange = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/check-assets-change-phone`,
    data,
  });

export const changeCustomerPhone = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/change-phone`,
    data,
  });

export const changeCustomerPhoneCreatePin = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/customer/change-phone/create-pin`,
    data,
  });

export const fetchSessionCallback = async (sessionId, callbackId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/${sessionId}/callback-data/${callbackId}`,
  });

export const createNewSession = async (merchantId, data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/sessions${merchantId ? `?merchant_id=${merchantId}` : ''
      }`,
    data,
  });

export const fetchFeeSetting = async (merchantId, showAllFees) => {
  const merchantIdPath = merchantId
    ? `fee-settings/?merchant_id=${merchantId}${showAllFees ? `&show_all=${showAllFees}` : ''
    }`
    : `global-fee-settings${showAllFees ? `/?show_all=${showAllFees}` : ''
    }`;
  return axios({
    method: 'get',
    url: `${baseUrl}/api/merchants/${merchantIdPath}`,
  });
};

export const createFeeSettings = async (body) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/merchants/create-fee-settings`,
    data: body,
  });

export const fetchEtpaySessionLink = async (invoiceNumbers, redirectUrl) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/etpay/create-invoices-payment-session`,
    data: { invoice_numbers: invoiceNumbers, redirect_url: redirectUrl },
  });

export const fetchCustomerUnpaidInvoices = async (customerId) => {
  const currentCustomerId = customerId ? `?customer_id=${customerId}` : '';
  return axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/unpaid${currentCustomerId}`,
  });
};

export const getPreregistrationUrl = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/users/login/get-preregistration-url`,
  });

export const getSessionStatus = async (sessionId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/${sessionId}/get-status`,
  });

export const fetchSettlementReports = async (
  currentPage = 0,
  minDate,
  maxDate,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settlements/reports?page=${currentPage}${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''
      }${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''}`,
  });

export const prepareAutoSettlement = async () =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/settlements/prepare-auto-settlement`,
  });

export const runAutoSettlement = async (data) => axios({
  method: 'post',
  url: `${baseUrl}/api/settlements/run-auto-settlement`,
  data,
});

export const getSettings = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settings`,
  });

export const setSetting = async (newSetting) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/settings/set-setting`,
    data: newSetting,
  });

export const createRenewBankIdSession = async (customerId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/customers/${customerId}/create-renew-bank-id`,
  });

export const fetchPreregistrations = async (
  currentPage = 0,
  searchQuery,
  showStatuses,
  showTypes,
  minDate,
  maxDate,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/preregistrations/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
      }${showStatuses &&
        showStatuses.length > 0 &&
        showStatuses.length !== SESSION_STATUSES.length
        ? `&statuses=${showStatuses.join(',')}`
        : ''
      }${showTypes &&
        showTypes.length > 0 &&
        showTypes.length !== SESSION_TYPES.length
        ? `&types=${showTypes.join(',')}`
        : ''
      }`,
  });

export const fetchPreregistrationSession = async (sessionId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/preregistrations/${sessionId}`,
  });

export const getMerchantApiKey = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/merchants/apikey`,
  });

export const fetchPaymentInvoiceUpdateJson = async (invoiceId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/${invoiceId}/payment-json`,
  });

export const getPaymentMethods = async (orderId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/orders/${orderId}/get-payment-methods`,
  });

export const changePaymentMethod = async (
  orderId,
  selectedPaymentMethod,
  isNewInvoicesStartFromPurchaseDate,
  newInvoicesStartAt,
) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/orders/${orderId}/change-payment-method`,
    data: {
      paymentMethod: selectedPaymentMethod,
      isNewInvoicesStartFromPurchaseDate: isNewInvoicesStartFromPurchaseDate,
      newInvoicesStartAt: newInvoicesStartAt,
    },
  });

export const fetchMerchantAliases = async (selectedMerchantId, currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/merchants/aliases/?page=${currentPage}
    ${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''}`,
  });

export const createMerchantAlias = async (selectedMerchantId, data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/merchants/create-alias${selectedMerchantId ? `/?merchant_id=${selectedMerchantId}` : ''}`,
    data,
  });

export const updateMerchantAlias = async (merchanAliasId, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/merchants/update-alias/${merchanAliasId}`,
    data,
  });

export const cancelOrder = async (orderId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/orders/${orderId}/cancel`,
  });

export const cancelOrderPartial = async (orderId, amountToCancel) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/orders/${orderId}/cancel-partial`,
    data: {
      amountToCancel: amountToCancel,
    },
  });

export const fetchRefunds = async (
  currentPage = 0,
  searchQuery,
  minDate,
  maxDate,
  selectedMerchantId,
  selectedStatus,
) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/refunds/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }${minDate ? `&min_date=${encodeURIComponent(minDate)}` : ''}${maxDate ? `&max_date=${encodeURIComponent(maxDate)}` : ''
      }${selectedMerchantId ? `&merchant_id=${selectedMerchantId}` : ''
      }${selectedStatus ? `&status=${selectedStatus}` : ''}`,
  });

export const setRefunded = async (refundId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/refunds/${refundId}/pay-manually`,
  });

export const setRefundedWithRadar = async (refundId) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/refunds/${refundId}/pay`,
  });

export const fetchRefund = async (refundId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/refunds/${refundId}`,
  });

export const updateCustomerBankInfo = async (customerId, customerBankInfo) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/customers/update-bank-info/${customerId}`,
    data: customerBankInfo,
  });

export const fetchSessionActions = async (sessionId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/${sessionId}/actions`,
  });

export const fetchSessionAction = async (sessionId, actionId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/sessions/${sessionId}/action-data/${actionId}`,
  });

export const fetchIpsBlacklist = async (currentPage = 0, searchQuery) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/ip-blacklist/?page=${currentPage}${searchQuery && searchQuery.length >= 3
      ? `&input=${encodeURIComponent(searchQuery)}`
      : ''
      }`,
  });

export const addIpToBlacklist = async (data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/customers/ip-blacklist`,
    data,
  });

export const deleteIpFromBlacklist = async (data) =>
  axios({
    method: 'delete',
    url: `${baseUrl}/api/customers/ip-blacklist`,
    data,
  });

export const fetchCustomerTemporaryBlocked = async (customerId) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/customers/temporary-blocked/${customerId}`,
  });

export const fetchInsospaSessionLink = async (invoiceNumbers, redirectUrl, paymentType) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/insospa/create-invoices-payment-session`,
    data: {
      invoice_numbers: invoiceNumbers,
      redirect_url: redirectUrl,
      payment_type: paymentType,
    },
  });

export const fetchFloidPaymentSessionLink = async (invoiceNumbers, redirectUrl) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/floid-payment/create-invoices-payment-session`,
    data: {
      invoice_numbers: invoiceNumbers,
      redirect_url: redirectUrl,
    },
  });

export const fetchCustomerPaymentMethods = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settings/customer-payment-methods`,
  });

export const loginExpressPayment = async (rut) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/users/login/express-payment`,
    data: rut,
  });

export const setExpressPaymentStatus = async (data) => {
  const token = cookie.get('token');
  fetch(`${baseUrl}/api/invoices/change-express-payment-status`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
    keepalive: true,
  });
};

export const fetchPaymentUrl = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/invoices/create-invoices-payment-session`,
    data,
  });

export const fetchRoutines = async (filterRoutineStatus, filterPaymentMethod, currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/routines/?page=${currentPage}${filterRoutineStatus === 'ACTIVE'
      ? '&status_filter=active'
      : '&status_filter=deactivated'
      }${filterPaymentMethod === 'PAY_IN_14_DAYS'
        ? '&payment_method_filter=PAY_IN_14_DAYS'
        : ''
      }`,
  });

export const createRoutine = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/routines`,
    data,
  });

export const deactivateRoutine = async (routineId) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/routines/deactivate/${routineId}`,
  });

export const fetchEmailTemplates = async (currentPage = 0) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/email-templates/?page=${currentPage}`,
  });

export const fetchAllEmailTemplates = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/email-templates/all/`,
  });

export const createEmailTemplate = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/email-templates`,
    data,
  });

export const updateEmailTemplate = async (emailTemplateId, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/email-templates/${emailTemplateId}`,
    data,
  });

export const fetchInvoiceRoutineEvents = async (invoiceNumber) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/invoices/${invoiceNumber}/routine-events`,
  });

export const getInvoiceLateFees = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/settings/invoice-late-fees`,
  });

export const setInvoiceLateFee = async (data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/settings/invoice-late-fee`,
    data,
  });

export const setInvoicePaid = async (invoiceNumber) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/invoices/${invoiceNumber}/paid`,
  });

export const sendEmailTemplate = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/email-templates/send-email-template`,
    data,
  });

export const setPayManually = async (customerId, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/customers/pay-manually/${customerId}`,
    data,
  });

export const setInvoiceCompensation = async (invoiceNumber, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/invoices/${invoiceNumber}/compensation`,
    data,
  });

export const setInvoiceHoldPeriod = async (data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/routines/set-hold-period`,
    data,
  });

export const fetchPermissionMatrix = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/user-management/permission-matrix`,
  });

export const fetchRolesList = async (page) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/user-management/roles?page=${page}`,
  });

export const createRole = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/user-management/create-role`,
    data,
  });

export const editRole = async (roleId, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/user-management/edit-role/${roleId}`,
    data,
  });

export const deleteRole = async (roleId) =>
  axios({
    method: 'delete',
    url: `${baseUrl}/api/user-management/delete-role/${roleId}`,
  });

export const fetchRolesData = async () =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/user-management/roles-data`,
  });

export const fetchUsersList = async (page, roles, searchQuery) =>
  axios({
    method: 'get',
    url: `${baseUrl}/api/user-management/users?page=${page}${roles && roles.length > 0 ? `&roles=${roles.join(',')}` : ''
      }${searchQuery && searchQuery.length >= 3 ? `&input=${encodeURIComponent(searchQuery)}` : ''
      }`,
  });

export const createUser = async (data) =>
  axios({
    method: 'post',
    url: `${baseUrl}/api/user-management/create-user`,
    data,
  });

export const editUser = async (userId, data) =>
  axios({
    method: 'put',
    url: `${baseUrl}/api/user-management/edit-user/${userId}`,
    data,
  });
