import createTheme from '@material-ui/core/styles/createTheme';
import typography from './typography';

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#5383ff',
    },
    grey: {
      300: '#fefefe',
      A100: '#f8f9ff',
    },
    secondary: {
      main: '#13345E',
    },
    error: {
      main: '#f83245',
    },
    success: {
      main: '#1bc943',
    },
    warning: {
      main: '#f4772e',
    },
    helpers: {
      primary: '#793de6',
      main: 'rgba(25, 46, 91, .035)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#13345E',
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: '#13345E',
      },
    },
  },
  typography,
});

export default MuiTheme;
