import './assets/base.scss';

import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import configureStore from './config/configureStore';
import AuthProvider from './providers/AuthProvider';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';

const store = configureStore();

export default () => (
  <Provider store={store}>
    <BrowserRouter basename="/">
      <CookiesProvider>
        <AuthProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </MuiPickersUtilsProvider>
        </AuthProvider>
      </CookiesProvider>
    </BrowserRouter>
  </Provider>
);
