import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { useAuthContext, roles } from '../../providers/AuthProvider';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'block',
    marginLeft: '10px',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

/**
 *
 */
export default function HeaderUserbox() {
  const classes = useStyles();
  const { onLogout, name, role } = useAuthContext();

  const ProfileComponent = () => (
    <span className="text-capitalize px-3 text-left d-flex align-items-center">
      <Box className="pr-1">
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
        >
          <FontAwesomeIcon icon={faUser} color="white" size="2x" />
        </StyledBadge>
      </Box>
      <Typography
        noWrap
        style={{ color: 'white' }}
        className={classes.title}
        variant="h5"
      >
        {name}
      </Typography>
    </span>
  );

  return (
    <>
      {role === 'CUSTOMER' ? (
        <>
          <Link to="/profile">
            <ProfileComponent />
          </Link>
        </>
      ) : (
        <ProfileComponent />
      )}
      <IconButton
        aria-label="Logout"
        color="inherit"
        onClick={() => {
          onLogout();
        }}
      >
        <ExitToAppIcon />
      </IconButton>
    </>
  );
}
