/**
 * @param props
 * @param props.rut
 * @param props.digitoVerificador
 */
export default function RutFormat({ rut, digitoVerificador = true }) {
  if (!rut) {
    return '';
  }

  let sRut = rut;
  let sRutFormateado = '';
  let sDV;

  if (digitoVerificador) {
    sDV = sRut.charAt(sRut.length - 1);
    sRut = sRut.slice(0, Math.max(0, sRut.length - 1));
  }

  while (sRut.length > 3) {
    sRutFormateado = `.${sRut.slice(-3)}${sRutFormateado}`;
    sRut = sRut.slice(0, Math.max(0, sRut.length - 3));
  }

  sRutFormateado = sRut + sRutFormateado;
  if (sRutFormateado !== '' && digitoVerificador) {
    sRutFormateado += `-${sDV}`;
  } else if (digitoVerificador) {
    sRutFormateado += sDV;
  }

  return sRutFormateado;
}
