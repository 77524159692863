export const MerchantBanks = [
  {
    name: 'Banco de Chile-Edwards-Citi',
    value: 'chile',
    key: 1,
  },
  {
    name: 'Banco Internacional',
    value: 'bancointernacional',
    key: 9,
  },
  {
    name: 'BancoEstado',
    value: 'estado',
    key: 12,
  },
  {
    name: 'Scotiabank',
    value: 'scotia',
    key: 14,
  },
  {
    name: 'Banco de Credito e Inversiones',
    value: 'bci',
    key: 16,
  },
  {
    name: 'Itaú CorpBanca',
    value: 'corpbanca',
    key: 27,
  },
  {
    name: 'Banco BICE',
    value: 'bice',
    key: 28,
  },
  {
    name: 'HSBC Bank Chile',
    value: 'hsbc',
    key: 31,
  },
  {
    name: 'Santander',
    value: 'santander',
    key: 37,
  },
  {
    name: 'Itau Chile',
    value: 'itau',
    key: 39,
  },
  {
    name: 'Banco Security',
    value: 'security',
    key: 49,
  },
  {
    name: 'Banco Falabella',
    value: 'falabella',
    key: 51,
  },
  {
    name: 'Banco Ripley',
    value: 'ripley',
    key: 53,
  },
  {
    name: 'Banco Consorcio',
    value: 'consorcio',
    key: 55,
  },
  {
    name: 'BBVA Chile',
    value: 'bbva',
    key: 504,
  },
  {
    name: 'Coopeuch',
    value: 'coopeuch',
    key: 672,
  },
  {
    name: 'Banco Caja',
    value: 'caja',
    key: 730,
  },
];

const dict = {};

// eslint-disable-next-line no-restricted-syntax
for (const b of MerchantBanks) {
  dict[b.value] = b.name;
}

export const MerchantBanksDict = dict;
