/**
 * @param props
 * @param props.phone
 */
export default function PhoneFormat({ phone = '' }) {
  if (!phone || phone.length !== 12) {
    return phone;
  }

  const phoneArr = phone.split('');
  return `${phoneArr[0]}${phoneArr[1]}${phoneArr[2]}-${phoneArr[3]}${phoneArr[4]}-${phoneArr[5]}${phoneArr[6]}${phoneArr[7]}-${phoneArr[8]}${phoneArr[9]}${phoneArr[10]}${phoneArr[11]}`;
}
