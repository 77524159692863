export const BankAccountTypes = [
  {
    name: 'Corriente',
    value: 'corriente',
  },
  {
    name: 'Vista',
    value: 'vista',
  },
];

let dict = {};

for (let a of BankAccountTypes) {
  dict[a.value] = a.name;
}

export const AccountTypeDict = dict;

export const AccountTypePossibleKeys = BankAccountTypes.map(b => b.value);
