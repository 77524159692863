import { Drawer, Hidden, Paper } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import {
  setSidebarHover,
  setSidebarToggle,
  setSidebarToggleMobile,
} from '../../reducers/ThemeOptions';
import Footer from '../Footer';
import SidebarHeader from '../SidebarHeader';
import NavItems from './navItems';

const Sidebar = ({
  setSidebarToggleMobile,
  sidebarToggleMobile,

  sidebarHover,
  setSidebarHover,
  sidebarToggle,
}) => {
  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover,
      })}
    >
      <NavItems />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
          onClose={closeDrawer}
        >
          <SidebarHeader />
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
          <Footer />
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          square
          className={clsx('app-sidebar-wrapper app-sidebar-wrapper-fixed', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
          })}
          open={sidebarToggle}
          elevation={3}
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
        >
          <SidebarHeader />
          <div
            className={clsx('app-sidebar-menu', {
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover,
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
            <Footer />
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarHover: (enable) => dispatch(setSidebarHover(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
