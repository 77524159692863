import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { resetMerchantFormData, resetMerchantAliasFormData } from '../../reducers/ThemeOptions';

import MerchantEditSettings from '../../components/MerchantEditSettings';
import MerchantEditFee from '../../components/MerchantEditFee';
import MerchantAliasEditSettings from '../../components/MerchantAliasEditSettings';
import { Header, Sidebar } from '../../layout-components';
import useRouter from 'utils/useRouter';

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarFixed,
    currentMerchantFormData,
    resetMerchantFormData,
    currentMerchantAliasFormData,
    resetMerchantAliasFormData,
  } = props;

  const router = useRouter();

  useEffect(() => {
    if (currentMerchantFormData.open) {
      resetMerchantFormData();
    }
    if (currentMerchantAliasFormData.open) {
      resetMerchantAliasFormData();
    }  }, [router.location.pathname]);

  return (
    <>
      <div className={clsx('app-wrapper')}>
        <Header />
          {currentMerchantFormData.open && (currentMerchantFormData.isEditMerchantFee === null ?
            <MerchantEditSettings /> : <MerchantEditFee />)}
          {currentMerchantAliasFormData.open && (<MerchantAliasEditSettings />)}
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed,
          })}
        >
          <Sidebar />
          <div
            className={clsx('app-content app-content-sidebar-fixed', {
              'app-content-sidebar-collapsed': sidebarToggle,
            })}
          >
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  currentMerchantFormData: state.ThemeOptions.currentMerchantFormData,
  currentMerchantAliasFormData: state.ThemeOptions.currentMerchantAliasFormData,
});

const mapDispatchToProps = (dispatch) => ({
  resetMerchantFormData: () => dispatch(resetMerchantFormData()),
  resetMerchantAliasFormData: () => dispatch(resetMerchantAliasFormData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
