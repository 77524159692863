export const CUSTOMER_PAYMENT_METHODS = {
  etpay: 'etpay_payment',
  webpay: 'webpay_payment',
  mach: 'mach_payment',
  floid: 'floid_payment',
};

export const CUSTOMER_PAYMENT_METHODS_ENABLED_LIST = [
  'isEtpayEnabled',
  'isFloidEnabled',
  'isWebpayEnabled',
  'isMachEnabled',
];

export const CUSTOMER_PAYMENT_METHODS_DIC = {
  isEtpayEnabled: 'etpay_payment',
  isFloidEnabled: 'floid_payment',
  isWebpayEnabled: 'webpay_payment',
  isMachEnabled: 'mach_payment',
};

export const EXPRESS_PAYMENT_STATUS = {
  CREATED: 'CREATED',
  CHOOSE_PAYMENT_METHOD_STARTED: 'CHOOSE_PAYMENT_METHOD_STARTED',
  PAYMENT_STARTED: 'PAYMENT_STARTED',
  PAID: 'PAID',
  CLOSED: 'CLOSED',
  EXPIRED: 'EXPIRED',
};
