import Alert from '@material-ui/lab/esm/Alert';
import Box from '@material-ui/core/esm/Box';
import Button from '@material-ui/core/esm/Button';
import CircularProgress from '@material-ui/core/esm/CircularProgress';
import CloseIcon from '@material-ui/icons/esm/Close';
import CloseTwoToneIcon from '@material-ui/icons/esm/CloseTwoTone';
import Drawer from '@material-ui/core/esm/Drawer';
import Fab from '@material-ui/core/esm/Fab';
import Grid from '@material-ui/core/esm/Grid';
import IconButton from '@material-ui/core/esm/IconButton';
import Select from '@material-ui/core/esm/Select';
import TextField from '@material-ui/core/esm/TextField';
import Tooltip from '@material-ui/core/esm/Tooltip';
import FormLabel from '@material-ui/core/esm/FormLabel';

import { Field, FieldArray, Form, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';

import { BankAccountTypes } from '../../constants/bankAccountTypes';
import { submitMerchant, updateMerchant } from '../../providers/api';
import { useAuthContext } from '../../providers/AuthProvider';

import {
  resetMerchantFormData,
  setMerchantFormData,
} from '../../reducers/ThemeOptions';

import { MerchantBanks } from '../../constants/merchant-banks';
import { SettlementIntervalTypes } from '../../constants/settlementIntervalTypes';
import { CreditDecisionModels } from '../../constants/credit-decision-models';

import MerchantFeesSettings from '../MerchantFeesSettings';

/**
 * @param props
 * @param props.currentMerchantFormData
 * @param props.setMerchantFormData
 * @param props.resetMerchantFormData
 */
function MerchantEditSettings({
  currentMerchantFormData,
  setMerchantFormData,
  resetMerchantFormData,
}) {
  const {
    open,
    memberId,
    merchantNumber,
    isAlertVisible,
    isLoading,
    ...formData
  } = currentMerchantFormData;
  const [errorMessage, setErrorMessage] = useState(null);

  const { isReadonly } = useAuthContext();

  const onFormSubmit = async (values, actions) => {
    setErrorMessage(null);
    const {
      ...data
    } = values;

    setMerchantFormData({
      isAlertVisible: false,
      isLoading: true,
    });

    try {
      if (typeof data.monthlyAvailableCredit === 'string') {
        data.monthlyAvailableCredit = Number(data.monthlyAvailableCredit.replace(',', '.'));
      }
      if (typeof data.dailyAvailableCredit === 'string') {
        data.dailyAvailableCredit = Number(data.dailyAvailableCredit.replace(',', '.'));
      }
      data.defaultPaymentMethod =
        data.defaultPaymentMethod === '' ? null : data.defaultPaymentMethod;

      const merchantFeesSettings = {};
      const rowMerchantFeeSettings = data.merchantFeesSettings;
      // eslint-disable-next-line no-restricted-syntax
      for (const mfs of rowMerchantFeeSettings) {
        if (mfs.merchantFee !== '' || mfs.merchantFlatFee !== '' || mfs.merchantConsumerFee !== '') {
          merchantFeesSettings[mfs.paymentMethod] = {
            merchantFee: mfs.merchantFee === '' ? null : mfs.merchantFee,
            merchantFlatFee: mfs.merchantFlatFee === '' ? null : mfs.merchantFlatFee,
            merchantConsumerFee: mfs.merchantConsumerFee === '' ? null : mfs.merchantConsumerFee,
          };
        }
      }

      data.merchantFeesSettings = merchantFeesSettings;

      data.creditDecisionModelId =
        data.creditDecisionModelId === '' ? null : Number(data.creditDecisionModelId);

      data.creditDecisionModelId =
        data.creditDecisionModelId === '' ? null : Number(data.creditDecisionModelId);

      const { data: responseData } = memberId
        ? await updateMerchant(memberId, data)
        : await submitMerchant(data);

      setMerchantFormData({
        token: responseData.token || '',
        open: false,
        merchantNumber: merchantNumber + 1,
        isAlertVisible: true,
        isLoading: false,
      });

      setTimeout(() => {
        setMerchantFormData({
          isAlertVisible: false,
        });
      }, 15000);
    } catch (error) {
      console.error('create/update merchant error', error);

      if (error?.response?.data?.message) {
        const { message } = error.response.data;
        if (Array.isArray(message)) {
          setErrorMessage(message.join(', '));
        } else {
          setErrorMessage(message);
        }
      }

      setMerchantFormData({
        isLoading: false,
      });
    }
  };

  const handleClose = () => {
    setMerchantFormData({
      isAlertVisible: false,
    });
  };

  return (
    <>
      {isAlertVisible && (
        <Alert className="alert-wrap" severity="info">
          {memberId ? (
            <FormattedMessage id="Edit successful" />
          ) : (
            <>
              Added. Please copy API key: {formData.token}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Alert>
      )}
      <Drawer
        anchor="right"
        elevation={4}
        open={currentMerchantFormData.open}
        variant="persistent"
        onClose={resetMerchantFormData}
      >
        <Box
          className="app-header-drawer"
          flexGrow={1}
          style={{ height: '100%' }}
        >
          <Tooltip
            placement="left"
            title={<FormattedMessage id="Close form" />}
          >
            <Fab
              className="drawer-close-btn"
              color="secondary"
              onClick={resetMerchantFormData}
            >
              <CloseTwoToneIcon />
            </Fab>
          </Tooltip>
          <PerfectScrollbar>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              minHeight="100%"
              p={4}
            >
              <div className="font-weight-bold font-size-xxl mb-4 text-black">
                {memberId ? (
                  <FormattedMessage id="Edit merchant" />
                ) : (
                  <FormattedMessage id="Create merchant" />
                )}
              </div>
              <motion.div
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                initial={{ opacity: 0, y: 20 }}
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <Formik
                    enableReinitialize
                    initialValues={formData}
                    onSubmit={onFormSubmit}
                  >
                    {({ handleChange }) => (
                      <Form noValidate="">
                        <Grid container spacing={2}>
                          <Grid item sm={6} xs={12}>
                            <Field name="name">
                              {({ field }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="Name" />}
                                  required={!memberId || false}
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="login">
                              {({ field }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="Login" />}
                                  required={!memberId || false}
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="password">
                              {({ field }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="Password" />}
                                  required={!memberId || false}
                                  type="password"
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="token">
                              {({ field }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="API Key" />}
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          {/* <Grid item sm={6} xs={12}> */}
                          {/*  <Field name="responseSignature"> */}
                          {/*    {({ field }) => ( */}
                          {/*      <TextField */}
                          {/*        fullWidth */}
                          {/*        label={<FormattedMessage id="Response Signature" />} */}
                          {/*        variant="outlined" */}
                          {/*        {...field} */}
                          {/*      /> */}
                          {/*    )} */}
                          {/*  </Field> */}
                          {/* </Grid> */}

                          <Grid item sm={6} xs={12}>
                            <FormLabel>
                              <FormattedMessage id="Select Bank" />
                            </FormLabel>
                            <Field name="bank">
                              {({ field, form, meta }) => (
                                <Select
                                  fullWidth
                                  disabled={isReadonly}
                                  native
                                  required={!memberId || false}
                                  value=""
                                  variant="outlined"
                                  {...field}
                                >
                                  <option value="">-</option>
                                  {MerchantBanks.map((item) => (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <FormLabel>
                              <FormattedMessage id="Select Account Type" />
                            </FormLabel>
                            <Field name="accountType">
                              {({ field, form, meta }) => (
                                <Select
                                  fullWidth
                                  disabled={isReadonly}
                                  native
                                  required={!memberId || false}
                                  value=""
                                  variant="outlined"
                                  {...field}
                                >
                                  <option value="">-</option>
                                  {BankAccountTypes.map((item) => (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="rut">
                              {({ field, form, meta }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="RUT" />}
                                  required={!memberId || false}
                                  type="text"
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="accountNumber">
                              {({ field, form, meta }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={
                                    <FormattedMessage id="Account Number" />
                                  }
                                  required={!memberId || false}
                                  type="text"
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="recipientName">
                              {({ field, form, meta }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={
                                    <FormattedMessage id="Recipient Name" />
                                  }
                                  required={!memberId || false}
                                  type="text"
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="email">
                              {({ field, form, meta }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="Email" />}
                                  required={!memberId || false}
                                  type="email"
                                  variant="outlined"
                                  {...field}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <FormLabel>
                              <FormattedMessage id="Select Credit Evaluation Model" />
                            </FormLabel>
                            <Field name="creditDecisionModelId">
                              {({ field }) => (
                                <Select
                                  fullWidth
                                  native
                                  disabled={isReadonly}
                                  required={!memberId || false}
                                  value=""
                                  variant="outlined"
                                  {...field}
                                >
                                  <option value="">-</option>
                                  {CreditDecisionModels.map((item) => (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <FormLabel>
                              <FormattedMessage id="Select Settlement Interval" />
                            </FormLabel>
                            <Field name="settlementInterval">
                              {({ field }) => (
                                <Select
                                  fullWidth
                                  disabled={isReadonly}
                                  native
                                  required={!memberId || false}
                                  variant="outlined"
                                  {...field}
                                >
                                  {SettlementIntervalTypes.map((item) => (
                                    <option value={item.value}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="monthlyAvailableCredit">
                              {({ field, form, meta }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="Monthly available credit" />}
                                  type="text"
                                  variant="outlined"
                                  {...field}
                                  onChange={(event) => {
                                    if (
                                      String(
                                        Number(
                                          event.target.value.replace(',', '.'),
                                        ),
                                      ) !== 'NaN'
                                    ) {
                                      handleChange(event);
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field name="dailyAvailableCredit">
                              {({ field, form, meta }) => (
                                <TextField
                                  fullWidth
                                  disabled={isReadonly}
                                  label={<FormattedMessage id="Daily available credit" />}
                                  type="text"
                                  variant="outlined"
                                  {...field}
                                  onChange={(event) => {
                                    if (
                                      String(
                                        Number(
                                            event.target.value.replace(',', '.'),
                                        ),
                                      ) !== 'NaN'
                                    ) {
                                      handleChange(event);
                                    }
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>

                          <Grid item sm={12}>
                            <FieldArray component={MerchantFeesSettings} name="merchantFeesSettings" />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              disabled={isReadonly}
                              Label={{
                                label: (
                                  <FormattedMessage id="Phone Verification Required" />
                                ),
                                className: 'mb-0-spacing',
                              }}
                              name="isPhoneVerificationRequired"
                              type="checkbox"
                            />
                            <br />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              disabled={isReadonly}
                              Label={{
                                label: (
                                  <FormattedMessage id="Merchant can create sessions in store" />
                                ),
                                className: 'mb-0-spacing',
                              }}
                              name="isCreateSessionOnDashboardEnabled"
                              type="checkbox"
                            />
                            <br />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              disabled={isReadonly}
                              Label={{
                                label: (
                                  <FormattedMessage id="Send WhatsApp notifications" />
                                ),
                                className: 'mb-0-spacing',
                              }}
                              name="isSendInvoiceNotificationsEnabled"
                              type="checkbox"
                            />
                            <br />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              disabled={isReadonly}
                              Label={{
                                label: (
                                  <FormattedMessage id="Use restricted decision model" />
                                ),
                                className: 'mb-0-spacing',
                              }}
                              name="isStrictDecisionModel"
                              type="checkbox"
                            />
                            <br />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              disabled={isReadonly}
                              Label={{
                                label: (
                                  <FormattedMessage id="Duplicate external session id or order id allowed" />
                                ),
                                className: 'mb-0-spacing',
                              }}
                              name="isDuplicateMerchantExternalIdAllowed"
                              type="checkbox"
                            />
                            <br />
                          </Grid>

                          <Grid item sm={6} xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              disabled={isReadonly}
                              Label={{
                                label: (
                                  <FormattedMessage id="Jumpseller" />
                                ),
                                className: 'mb-0-spacing',
                              }}
                              name="isJumpseller"
                              type="checkbox"
                            />
                            <br />
                          </Grid>
                        </Grid>

                        {errorMessage && (
                          <Grid container justify="center" spacing={4}>
                            <Grid item>
                              <div className="text-danger">{errorMessage}</div>
                            </Grid>
                          </Grid>
                        )}
                        <br />

                        <Grid container justify="space-between" spacing={4}>
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={resetMerchantFormData}
                            >
                              <FormattedMessage id="Cancel" />
                            </Button>
                          </Grid>
                          <Grid item>
                            {isLoading && (
                              <CircularProgress
                                className="circular-progress"
                                color="secondary"
                                size={18}
                              />
                            )}
                            <Button
                              disableElevation
                              color="primary"
                              disabled={isLoading || isReadonly}
                              type="submit"
                              variant="contained"
                            >
                              <FormattedMessage id="Save" />
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </div>
              </motion.div>
            </Box>
          </PerfectScrollbar>
        </Box>
      </Drawer>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentMerchantFormData: state.ThemeOptions.currentMerchantFormData,
});

const mapDispatchToProps = (dispatch) => ({
  setMerchantFormData: (payload) => dispatch(setMerchantFormData(payload)),
  resetMerchantFormData: () => dispatch(resetMerchantFormData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantEditSettings);
