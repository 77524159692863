/**
 * @param props
 * @param props.type
 * @param props.children
 */
export default function CustomBadge({ type = '', onClick = () => {}, children }) {
  let classAttr = '';
  switch (type) {
    case 'success':
      classAttr = 'badge badge-success badge-order-success';
      break;
    case 'success-secondary':
      classAttr = 'badge bg-neutral-success text-success';
      break;
    case 'danger':
      classAttr = 'badge badge-danger badge-order-danger';
      break;
    case 'danger-secondary':
      classAttr = 'badge bg-neutral-danger text-danger';
      break;
    case 'warning':
      classAttr = 'badge badge-warning badge-order-warning';
      break;
    case 'warning-secondary':
      classAttr = 'badge babg-neutral-warning text-warning';
      break;
    case 'info':
      classAttr = 'badge badge-info badge-order-info';
      break;
    case 'info-secondary':
      classAttr = 'badgebg-neutral-info text-info';
      break;
    // WARNING: Do not use these
    case 'error':
      classAttr = 'badge badge-danger';
      break;
    case 'error-secondary':
      classAttr = 'badge bg-neutral-danger text-danger';
      break;
    case 'denied':
      classAttr = 'badge badge-warning';
      break;
    case 'expired':
      classAttr = 'badge bg-neutral-warning text-warning';
      break;
    // END.
    default:
      classAttr = 'badge badge-primary';
  }

  return <span className={classAttr} onClick={onClick}>{children}</span>;
}
