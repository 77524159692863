import List from '@material-ui/core/esm/List';
import Typography from '@material-ui/core/esm/Typography';

import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';

import useRouter from '../../utils/useRouter';
import SidebarMenuListItem from './SidebarMenuListItem';

function SidebarMenuList(props) {
  const { pages, ...rest } = props;
  return (
    <List className="p-0">
      {pages.reduce(
        (items, page, index) =>
          reduceChildRoutes({ items, page, ...rest }, index),
        [],
      )}
    </List>
  );
}

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = ({ router, items, page, depth }, index) => {
  let open = false;

  if (page.content) {
    if (page.content.length > 0) {
      for (let i = 0; i < page.content.length; i++) {
        const subPage = page.content[i];
        if (
          subPage.to &&
          matchPath(router.location.pathname, {
            path: subPage.to,
            exact: false,
          })
        ) {
          open = true;
        }
      }
    }

    items.push(
      <SidebarMenuListItem
        key={index}
        depth={depth}
        disabled={page.disabled}
        icon={page.icon}
        label={page.badge}
        open={Boolean(open)}
        title={page.label}
      >
        <div className="sidebar-menu-children py-2">
          <SidebarMenuList
            depth={depth + 1}
            pages={page.content}
            router={router}
          />
        </div>
      </SidebarMenuListItem>,
    );
  } else {
    items.push(
      <SidebarMenuListItem
        key={index}
        depth={depth}
        disabled={page.disabled}
        href={page.to}
        icon={page.icon}
        label={page.badge}
        title={page.label}
      />,
    );
  }

  return items;
};

function SidebarMenu(props = {
  component: 'nav',
}) {
  const { title, pages, className, component: Component, ...rest } = props;

  const router = useRouter();

  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} router={router} />
    </Component>
  );
}

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

export default SidebarMenu;
