import {
  Container,
  AppBar,
  Button,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Popup from 'reactjs-popup';

import ProjectLogo from '../../assets/images/Cleo Logo.svg';
// todo replace default avatar?
import DefaultAvatarImg from '../../assets/images/default-avatar.jpeg';
import MenuBurgerImg from '../../assets/images/menu-burger.png';
import CollapseArrowImg from '../../assets/images/collapse-arrow.png';
import ExpandArrowImg from '../../assets/images/expand-arrow.png';
import UserImage from '../../assets/images/user.svg';
import ShopImage from '../../assets/images/shop.svg';
import SignOut from '../../assets/images/sign-out.svg';
import Cancel from '../../assets/images/cancel.svg';

import { useAuthContext } from '../../providers/AuthProvider';
import useQuery from '../../utils/useQuery';

import PhoneFormat from '../../components/Format/PhoneFormat';
import RutFormat from '../../components/Format/RutFormat';

const HeaderContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 110,
  },
})(Container);

const CustomerHeader = ({ customerInfo }) => {
  const selectedLink = window.location.pathname;
  const [isOpenedProfileMenu, setOpenProfileMenu] = useState(false);
  const [isCollapsedMenu, setCollapsedMenu] = useState(true);

  const mediaMobile = useMediaQuery('(max-width: 800px)');
  const { onLogout, role } = useAuthContext();

  let homeLink = '/';
  let myOrdersLink = '/my-orders';
  let linkBack = '/';
  if (role === 'ADMIN') {
    const query = useQuery();
    homeLink = `/customer-dashboard?customer_id=${query.get('customer_id')}`;
    myOrdersLink = `/customer-dashboard/my-orders?customer_id=${query.get(
      'customer_id',
    )}`;
    linkBack = `/customers/${query.get('customer_id')}`;
  }

  const сustomerInfoBlock = () => (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h6">
        {customerInfo.firstName ? customerInfo.firstName : ''}
      </Typography>
      <Typography variant="h6">
        <PhoneFormat phone={customerInfo.phone ? customerInfo.phone : ''} />
      </Typography>
      <Typography variant="h6">
        {customerInfo && (
          <RutFormat rut={customerInfo.rut ? customerInfo.rut : ''} />
        )}
      </Typography>
    </div>
  );

  const renderMenu = () => (
    <div style={mediaMobile ? { width: '100%' } : {}}>
      <ul className="navigation-container">
        <li
          className={
            selectedLink === '/' || selectedLink === '/customer-dashboard'
              ? 'selected'
              : ''
          }
        >
          <Link to={homeLink}>
            <FormattedMessage id="Start" />
          </Link>
        </li>
        <li
          className={
            selectedLink === '/my-orders' ||
            selectedLink === '/customer-dashboard/my-orders'
              ? 'selected'
              : ''
          }
        >
          <Link to={myOrdersLink}>
            <FormattedMessage id="My purchases" />
          </Link>
        </li>

        {role === 'ADMIN' ? (
          <li>
            <Link to={linkBack}>
              <FormattedMessage id="Go back" />
            </Link>
          </li>
        ) : (
          <>
            <li className={selectedLink === '/support' ? 'selected' : ''}>
              <Link to="/support">
                <FormattedMessage id="Support" />
              </Link>
            </li>
            <Popup
              trigger={
                <li
                  className={
                    selectedLink === '/my-profile' ||
                    selectedLink === '/my-profile/edit'
                      ? 'user-select-container selected'
                      : 'user-select-container'
                  }
                >
                  <img
                    className="user-logo"
                    alt="user-logo"
                    src={
                      (customerInfo && customerInfo.avatarUrl) ||
                      DefaultAvatarImg
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = DefaultAvatarImg;
                    }}
                  />
                  {customerInfo && customerInfo.firstName}
                  {isOpenedProfileMenu ? (
                    <img
                      className="profile-select-arrow"
                      src={CollapseArrowImg}
                    />
                  ) : (
                    <img
                      className="profile-select-arrow"
                      src={ExpandArrowImg}
                    />
                  )}
                </li>
              }
              nested
              onOpen={() => setOpenProfileMenu(!isOpenedProfileMenu)}
              onClose={() => setOpenProfileMenu(!isOpenedProfileMenu)}
              on={['hover', 'focus']}
              position="bottom right"
            >
              <div className="profile-modal">
                <Typography variant="h3" className="user-name">
                  {customerInfo && customerInfo.fullName}
                </Typography>
                {/*<Link onClick={() => setOpenProfileMenu(false)} to="/my-profile/edit">*/}
                {/*    <Typography variant="h6" className="edit-profile">*/}
                {/*        <FormattedMessage id="Edit profile" />*/}
                {/*    </Typography>*/}
                {/*</Link>*/}
                <ul>
                  <Link
                    onClick={() => setOpenProfileMenu(false)}
                    to="/my-profile"
                  >
                    <li>
                      <img src={UserImage} />
                      <FormattedMessage id="My Profile" />
                    </li>
                  </Link>
                  <li>
                    <a href="https://www.cleo.cl/tiendas/">
                      <img src={ShopImage} />
                      <FormattedMessage id="Stores" />
                    </a>
                  </li>
                  <li onClick={onLogout}>
                    <img src={SignOut} />
                    <FormattedMessage id="Sign off" />
                  </li>
                </ul>
              </div>
            </Popup>
          </>
        )}
      </ul>
    </div>
  );

  return (
    <AppBar
      color="white"
      position={mediaMobile ? 'relative' : 'fixed'}
      elevation={1}
    >
      <HeaderContainer
        style={
          mediaMobile
            ? { flexDirection: 'column', height: 'auto' }
            : { justifyContent: 'space-between', alignItems: 'center' }
        }
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: mediaMobile ? '100%' : '',
            padding: mediaMobile ? '10px 0' : '',
          }}
        >
          <div>
            <Link to="/">
              <img alt="Cleo" src={ProjectLogo} />
            </Link>
          </div>

          {role === 'ADMIN' &&
            mediaMobile &&
            customerInfo &&
            сustomerInfoBlock()}

          {mediaMobile && (
            <Button onClick={() => setCollapsedMenu(!isCollapsedMenu)}>
              <img src={!isCollapsedMenu ? Cancel : MenuBurgerImg} />
            </Button>
          )}
        </div>

        {isCollapsedMenu || !mediaMobile ? null : renderMenu()}

        {role === 'ADMIN' &&
          !mediaMobile &&
          customerInfo &&
          сustomerInfoBlock()}

        {mediaMobile ? null : renderMenu()}
      </HeaderContainer>
    </AppBar>
  );
};

export default CustomerHeader;
