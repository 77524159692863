import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import AppBar from '@material-ui/core/esm/AppBar';
import Box from '@material-ui/core/esm/Box';
import Button from '@material-ui/core/esm/Button';
import CloseIcon from '@material-ui/icons/esm/Close';
import Dialog from '@material-ui/core/esm/Dialog';
import DialogContent from '@material-ui/core/esm/DialogContent';
import DialogTitle from '@material-ui/core/esm/DialogTitle';
import Grid from '@material-ui/core/esm/Grid';
import Hidden from '@material-ui/core/esm/Hidden';
import IconButton from '@material-ui/core/esm/IconButton';
import MenuOpenRoundedIcon from '@material-ui/icons/esm/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/esm/MenuRounded';
import TextField from '@material-ui/core/esm/TextField';
import Tooltip from '@material-ui/core/esm/Tooltip';
import Typography from '@material-ui/core/esm/Typography';

import Cookies from 'js-cookie';
import withStyles from '@material-ui/styles/esm/withStyles';
import {
  setCreateNewSession,
  setLanguage,
  setSidebarToggle,
  setSidebarToggleMobile,
} from '../../reducers/ThemeOptions';
import projectLogo from '../../assets/images/Cleo Logo.svg';

import HeaderLogo from '../HeaderLogo';
import HeaderUserbox from '../HeaderUserbox';
import { useAuthContext } from '../../providers/AuthProvider';
import { getMerchantApiKey } from '../../providers/api';

const StyledButton = withStyles(() => ({
  root: {
    color: 'white!important',
    border: '1px solid!important',
    '&:disabled': {
      color: '#999!important',
    },
  },
}))(Button);

const Header = ({
  sidebarToggleMobile,
  setSidebarToggleMobile,
  setSidebarToggle,
  sidebarToggle,
  isCollapsedLayout,
  language,
  setLanguage,
  setCreateNewSession,
  sessionsFiltersMerchant,
}) => {
  const intl = useIntl();
  const { isCreateSessionOnDashboardEnabled, role } = useAuthContext();
  const [apiKey, setApiKey] = useState('Loading...');
  const [showApiKey, setShowApiKey] = useState();

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const handleShowApiKey = async () => {
    const { data } = await getMerchantApiKey();

    setApiKey(data);
    setShowApiKey(true);
  };

  const handleCloseApiKeyWindow = async () => {
    setApiKey('Loading...');
    setShowApiKey(false);
  };

  return (
    <>
      <AppBar
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': isCollapsedLayout,
        })}
        color="secondary"
        elevation={11}
        position="fixed"
      >
        {!isCollapsedLayout && <HeaderLogo />}
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box className="app-logo-wrapper" title="Cleo">
              <Link className="app-logo-link" to="/dashboard">
                <IconButton
                  className="app-logo-btn"
                  color="primary"
                  size="medium"
                >
                  <img alt="Cleo" className="app-logo-img" src={projectLogo} />
                </IconButton>
              </Link>
              <Hidden smDown>
                <Box className="app-logo-text">CLEO</Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle,
                  })}
                >
                  <Tooltip
                    placement="right"
                    title={intl.formatMessage({ id: 'Toggle Sidebar' })}
                  >
                    <IconButton
                      className="btn-inverse"
                      color="inherit"
                      size="medium"
                      onClick={toggleSidebar}
                    >
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Hidden>

          <Box className="d-flex align-items-center" style={{ width: '100%' }}>
            <Grid container justifyContent="space-between" alignItems = "center" spacing={1}>
              <Grid item xs={4}>
                <Box
                  className="d-flex align-items-center"
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  {isCreateSessionOnDashboardEnabled
                    ? (
                      <>
                        <StyledButton
                          disabled={role !== 'MERCHANT'}
                          variant="outlined"
                          onClick={() => {
                            setCreateNewSession(true);
                          }}
                        >
                          <FormattedMessage id="Create new session" />
                        </StyledButton>
                        {' '}&nbsp;{' '}
                      </>
                    ) : null}

                  <StyledButton
                    disabled={role !== 'MERCHANT'}
                    variant="outlined"
                    onClick={handleShowApiKey}
                  >
                    <FormattedMessage id="Show API key" />
                  </StyledButton>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  className="d-flex align-items-center"
                  style={{
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      style={{
                        cursor: 'pointer',
                        padding: '5px 15px',
                        margin: '0 5px',
                        border: `1px solid ${
                          language === 'es' ? 'white' : 'transparent'
                        }`,
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        Cookies.set('language', 'es');
                        setLanguage('es');
                      }}
                    >
                      Es
                    </Box>
                    <Box
                      style={{
                        cursor: 'pointer',
                        padding: '5px 15px',
                        margin: '0 5px',
                        border: `1px solid ${
                          language === 'es' ? 'transparent' : 'white'
                        }`,
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        Cookies.set('language', 'en');
                        setLanguage('en');
                      }}
                    >
                      En
                    </Box>
                  </Box>
                  <HeaderUserbox />
                  <Box className="toggle-sidebar-btn-mobile">
                    <Tooltip
                      placement="right"
                      title={intl.formatMessage({ id: 'Toggle Sidebar' })}
                    >
                      <IconButton
                        color="inherit"
                        size="medium"
                        onClick={toggleSidebarMobile}
                      >
                        {sidebarToggleMobile ? (
                          <MenuOpenRoundedIcon />
                        ) : (
                          <MenuRoundedIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </AppBar>

      <Dialog
        aria-labelledby="responsive-dialog-title"
        open={showApiKey}
        onClose={handleCloseApiKeyWindow}
      >
        <DialogTitle>
          <Typography variant="h4">
            <FormattedMessage id="API Key" />
          </Typography>
          <IconButton
            aria-label="close"
            style={{
              position: 'absolute',
              right: 4,
              top: 4,
              color: '#999',
            }}
            onClick={handleCloseApiKeyWindow}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ boxSizing: 'border-box', padding: '24' }}>
          <TextField
            disabled
            readonly
            defaultValue={apiKey}
            size="small"
            variant="outlined"
          />
          {' '}&nbsp;{' '}
          <CopyToClipboard
            text={apiKey}
            onCopy={() => alert(intl.formatMessage({id: 'Copied'}))}
          >
            <Button
              disableElevation
              color="primary"
              size="large"
              variant="contained"
            >
              Copy
            </Button>
          </CopyToClipboard>

        </DialogContent>
      </Dialog>

    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  language: state.ThemeOptions.language,
  createNewSession: state.ThemeOptions.createNewSession,
  sessionsFiltersMerchant: state.Filters.sessionsFiltersMerchant,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setLanguage: (payload) => dispatch(setLanguage(payload)),
  setCreateNewSession: (payload) => dispatch(setCreateNewSession(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
