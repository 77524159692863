function isAccessGranted(role, permissions, permission) {
  if (role !== 'ADMIN') {
    return true;
  }

  if (!permissions) {
    return false;
  }

  return permissions.includes(permission);
}

export default isAccessGranted;
