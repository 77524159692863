import './styles.scss';

import { connect } from 'react-redux';
import { useEffect } from 'react';

import Container from '@material-ui/core/esm/Container';

import { getCustomerInfo, fetchCustomerPaymentMethods } from '../providers/api';
import { setCustomerInfo, setCustomerPaymentMethods, setCustomerDefaultPaymentMethod } from '../reducers/ThemeOptions';
import useQuery from '../utils/useQuery';
import CustomerHeader from './header';
import { CUSTOMER_PAYMENT_METHODS_ENABLED_LIST, CUSTOMER_PAYMENT_METHODS_DIC } from '../constants/customerPaymentMethods';

const Layout = ({ children, setCustomerInfo, customerInfo, setCustomerPaymentMethods, setCustomerDefaultPaymentMethod }) => {
  const query = useQuery();
  const customerId = query.get('customer_id');

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await getCustomerInfo(customerId);
        setCustomerInfo(data);
      } catch (error) {
        // not implemented method
        // setServerError('Something went wrong, please try again later');
        console.log('Error while fetch customer info', error);
      }
    };
    fetch();
  }, [customerId, setCustomerInfo]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: customerPaymentMethods } = await fetchCustomerPaymentMethods();
        setCustomerPaymentMethods(customerPaymentMethods);

        const availibalPaymentMethods = CUSTOMER_PAYMENT_METHODS_ENABLED_LIST.filter(
          (el) => customerPaymentMethods[el]
        );

        if (availibalPaymentMethods.length > 0) {
          setCustomerDefaultPaymentMethod(CUSTOMER_PAYMENT_METHODS_DIC[availibalPaymentMethods[0]]);
        }
      } catch (error) {
        console.log('Error while fetch customer payment methods', error);
      }
    };

    fetch();
  }, [setCustomerPaymentMethods, setCustomerDefaultPaymentMethod]);

  return (
    <div>
      <CustomerHeader customerInfo={customerInfo} />
      <Container>
        <div className="customer-container">{children}</div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  customerInfo: state.ThemeOptions.customerInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerInfo: (payload) => dispatch(setCustomerInfo(payload)),
  setCustomerPaymentMethods: (payload) => dispatch(setCustomerPaymentMethods(payload)),
  setCustomerDefaultPaymentMethod: (payload) => dispatch(setCustomerDefaultPaymentMethod(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
