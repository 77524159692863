import { Button, ListItem } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

function SidebarMenuListItem(props) {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    label: Label,
    disabled,
    open,
    ...rest
  } = props;
  const [isExpanded, setIsExpanded] = useState(open);

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft *= depth;
  }

  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        disableGutters
        className={clsx('app-sidebar-item', className)}
      >
        <Button
          disableRipple
          activeClassName="active-item"
          className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
          color="primary"
          style={style}
          variant="text"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {Icon && <Icon className="app-sidebar-icon" />}
          {title}
          {isExpanded ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
        </Button>
        {isExpanded && children}
      </ListItem>
    );
  }
  return (
    <ListItem
      {...rest}
      disableGutters
      className={clsx('app-sidebar-item', className)}
    >
      <Button
        disableRipple
        exact
        activeClassName="active-item"
        className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
        color="primary"
        component={CustomRouterLink}
        disabled={disabled}
        style={style}
        to={href}
        variant="text"
      >
        {Icon && <Icon className="app-sidebar-icon" />}
        {title}
        {Label && (
          <span className="menu-item-label">
            <Label />
          </span>
        )}
      </Button>
    </ListItem>
  );
}

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false,
};

export default SidebarMenuListItem;
