/* eslint-disable no-restricted-imports */
import AdjustIcon from '@material-ui/icons/Adjust';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import SettingsIcon from '@material-ui/icons/Settings';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import BusinessIcon from '@material-ui/icons/Business';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import EmailIcon from '@material-ui/icons/Email';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { FormattedMessage } from 'react-intl';

import { useAuthContext } from '../../providers/AuthProvider';
import SidebarMenu from '../SidebarMenu';
import { PERMISSION_MATRIX_IDS } from '../../constants/permissionMatrixIds';
import isAccessGranted from '../../utils/isAccessGranted';

const CUSTOMER = 'CUSTOMER';
const MERCHANT = 'MERCHANT';
const ADMIN = 'ADMIN';

const hasRights = (
  role,
  pageRestrictions,
  disableForReadonly,
  isReadonly,
  permissions,
  permission,
) => {
  if (role === ADMIN) {
    if (disableForReadonly && isReadonly) {
      return false;
    }

    return isAccessGranted(role, permissions, permission);
  }

  if (role === MERCHANT) {
    return pageRestrictions === MERCHANT || pageRestrictions === CUSTOMER;
  }

  return pageRestrictions === CUSTOMER;
};

function NavItems() {
  const { role, isReadonly, permissions } = useAuthContext();

  const navItems = [
    {
      label: <FormattedMessage id="Dashboard" />,
      to: '/dashboard',
      icon: DashboardIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_DASHBOARD,
    },
    {
      label: (
        <FormattedMessage
          id={role === MERCHANT ? 'Customers Merchant' : 'Customers'}
        />
      ),
      to: '/customers',
      icon: PersonIcon,
      restricted: MERCHANT,
      permission: PERMISSION_MATRIX_IDS.VIEW_CUSTOMERS,
    },
    {
      label: (
        <FormattedMessage
          id={
            role === MERCHANT
              ? 'Invoices Merchant'
              : (role === CUSTOMER
                ? 'Invoices Customer'
                : 'Invoices')
          }
        />
      ),
      to: '/invoices',
      icon: CreditCardIcon,
      restricted: CUSTOMER,
      permission: PERMISSION_MATRIX_IDS.VIEW_INVOICES,
    },
    {
      label: <FormattedMessage id="Orders" />,
      to: '/orders',
      icon: ReceiptIcon,
      restricted: MERCHANT,
      permission: PERMISSION_MATRIX_IDS.VIEW_ORDERS,
    },
    {
      label: <FormattedMessage id="Sessions" />,
      to: '/sessions',
      icon: FormatListNumberedIcon,
      restricted: MERCHANT,
      permission: PERMISSION_MATRIX_IDS.VIEW_SESSIONS,
    },
    {
      label: <FormattedMessage id="Preregistrations" />,
      to: '/preregistrations',
      icon: HowToRegIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_PREREGISTRATION,
    },
    {
      label: <FormattedMessage id="Settlements" />,
      to: '/settlements',
      icon: AdjustIcon,
      restricted: MERCHANT,
      permission: PERMISSION_MATRIX_IDS.VIEW_SETTLEMENTS,
    },
    {
      label: <FormattedMessage id="Settlement reports" />,
      to: '/settlement-reports',
      icon: BusinessIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_SETTLEMENTS_REPORTS,
    },
    {
      label: <FormattedMessage id="Merchants" />,
      to: '/merchants',
      icon: StorefrontIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_MERCHANTS,
    },
    {
      label: <FormattedMessage id="Merchant aliases" />,
      to: '/merchant-aliases',
      icon: StoreIcon,
      restricted: MERCHANT,
      permission: PERMISSION_MATRIX_IDS.VIEW_MERCHANT_ALIASES,
    },
    {
      label: <FormattedMessage id="Settings" />,
      to: '/settings',
      icon: SettingsIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_SETTINGS,
    },
    {
      label: <FormattedMessage id="Refunds" />,
      to: '/refunds',
      icon: MoneyOffIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_REFUNDS,
    },
    {
      label: <FormattedMessage id="IPs blacklist" />,
      to: '/ips-blacklist',
      icon: ListAltIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_IPS_BLACKLIST,
    },
    {
      label: <FormattedMessage id="Routines" />,
      to: '/routines',
      icon: AvTimerIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_ROUTINES,
    },
    {
      label: <FormattedMessage id="Email templates" />,
      to: '/email-templates',
      icon: EmailIcon,
      restricted: ADMIN,
      permission: PERMISSION_MATRIX_IDS.VIEW_EMAIL_TEMPLATES,
    },
    {
      label: <FormattedMessage id="User management" />,
      to: '/test-route',
      icon: GroupAddOutlinedIcon,
      restricted: ADMIN,
      content: [
        {
          label: <FormattedMessage id="Users" />,
          to: '/users',
          icon: PeopleOutlineIcon,
          restricted: ADMIN,
        },
        {
          label: <FormattedMessage id="Roles" />,
          to: '/roles',
          icon: AccountTreeOutlinedIcon,
          restricted: ADMIN,
        },
      ],
      permission: PERMISSION_MATRIX_IDS.VIEW_USER_MANAGEMENT,
    },
  ];

  const navItemsFiltered = navItems
    .filter((nav) => (nav.restricted
      ? hasRights(
        role,
        nav.restricted,
        nav.disableForReadonly,
        isReadonly,
        permissions,
        nav.permission,
      )
      : true));

  return (
    <SidebarMenu component="div" pages={navItemsFiltered} title="" />
  );
}

export default NavItems;
